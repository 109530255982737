<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :model="formData"
        inline
        :rules="searchRules"
      >
        <el-form-item prop="dateRange">
          <el-date-picker
            v-model="formData.dateRange"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>

        <el-form-item prop="countryCodeList">
          <el-select
            v-model="formData.countryCodeList"
            style="width: 240px"
            multiple
            clearable
            filterable
            :filter-method="filterCountryMethod"
            collapse-tags
            value-key="countryCode"
            placeholder="Country"
            @visible-change="filterNationList = nationList"
          >
            <div class="pre-header">
              <div
                class="select-all"
                @click="changeSelectState('countryCodeList', nationList, formData)"
              >
                {{ selectButtonLabel(formData.countryCodeList, nationList) }}
              </div>
            </div>
            <el-option
              v-for="item in filterNationList"
              :key="item.countryCode"
              :label="item.nation"
              :value="item"
            />
          </el-select>
        </el-form-item>

        <!-- <el-form-item prop="agencyList">
          <el-select
            v-model="formData.agencyList"
            style="width: 240px"
            multiple
            clearable
            filterable
            :filter-method="filterAgencyMethod"
            collapse-tags
            placeholder="Agency"
            @visible-change="filterAgencyList = agencyList"
          >
            <div class="pre-header">
              <div
                class="select-all"
                @click="changeSelectState('agencyList', agencyList, formData)"
              >
                {{ selectButtonLabel(formData.agencyList, agencyList) }}
              </div>
            </div>
            <el-option
              v-for="agency in filterAgencyList"
              :key="agency"
              :label="agency"
              :value="agency"
            />
          </el-select>
        </el-form-item>

        <el-form-item prop="mediaSourceList">
          <el-select
            v-model="formData.mediaSourceList"
            style="width: 240px"
            multiple
            clearable
            filterable
            :filter-method="filterMediaSourceMethod"
            collapse-tags
            placeholder="Media Source"
            @visible-change="filterMediaSourceList = mediaSourceList"
          >
            <div class="pre-header">
              <div
                class="select-all"
                @click="changeSelectState('mediaSourceList', mediaSourceList, formData)"
              >
                {{ selectButtonLabel(formData.mediaSourceList, mediaSourceList) }}
              </div>
            </div>
            <el-option
              v-for="media in filterMediaSourceList"
              :key="media"
              :label="media"
              :value="media"
            />
          </el-select>
        </el-form-item> -->

        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            Search
          </el-button>
        </el-form-item>
      </el-form>
      <template #right>
        <el-button
          type="text"
          @click="downloadData"
        >
          <i
            class="fa fa-cloud-download"
            style="font-size: 36px;"
            title="Download"
          />
        </el-button>
      </template>
    </BHeader>

    <div
      v-loading="listInfo.loading"
      class="overview"
    >
      <div class="left">
        <div class="items">
          <div class="num">
            {{ listInfo.sumLtv1 }}
          </div>
          <div class="name">
            Sum(All Channel - Revenue) USD
          </div>
        </div>
        <div class="items">
          <div class="num">
            {{ listInfo.sumCost }}
          </div>
          <div class="name">
            Sum(All Channel - Cost) USD
          </div>
        </div>
        <div class="items">
          <div class="num">
            {{ listInfo.sumRoi }}
          </div>
          <div class="name">
            Sum(All Channel - Revenue) / Sum(All Channel - Cost)
          </div>
        </div>
      </div>
      <div class="right">
        <el-button
          type="primary"
          plain
          @click="handleCreateNewData"
        >
          新建数据
        </el-button>
      </div>
    </div>

    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      style="width: 100%"
      border
    >
      <el-table-column
        label="Date"
        prop="elapsedTime"
        min-width="120px"
      />
      <el-table-column
        label="Country"
        prop="countryCode"
        min-width="180px"
      />
      <el-table-column
        label="Channel"
        prop="channel"
        min-width="120px"
      />
      <!-- :formatter="channelFormatter" -->
      <el-table-column
        label="Agency"
        prop="agency"
        min-width="120px"
      />
      <el-table-column
        label="Media Source"
        prop="mediaSource"
        min-width="120px"
      />
      <el-table-column
        label="Cost(USD)"
        prop="cost"
        min-width="120px"
      />
      <el-table-column
        label="Revenue（USD）"
        prop="revenue"
        width="140px"
      />
      <el-table-column
        label="操作"
        width="140px"
      >
        <template #default="{row}">
          <BTextButton
            title="删除"
            icon="el-icon-delete"
            @click="handleDeleteRowData(row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <Pagination
      v-show="!listInfo.loading"
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
    <!-- 新建 | 修改 数据 -->
    <el-dialog
      :title="dialogInfo.title"
      :visible.sync="dialogInfo.show"
      width="600px"
      @close="handleDialogClose"
    >
      <el-form
        ref="subForm"
        v-loading="dialogInfo.loading"
        :model="dialogInfo.formData"
        :rules="dialogInfo.formRules"
        label-width="120px"
      >
        <el-form-item
          label="Date"
          prop="elapsedTime"
        >
          <el-date-picker
            v-model="dialogInfo.formData.elapsedTime"
            type="date"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item
          label="Country"
          prop="countryCode"
        >
          <el-select
            v-model="dialogInfo.formData.countryCode"
            style="width: 220px"
            clearable
            filterable
            placeholder="Country"
          >
            <el-option
              v-for="item in nationList"
              :key="item.countryCode"
              :label="item.nation"
              :value="item.countryCode"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="Agency"
          prop="agency"
        >
          <el-input
            v-model="dialogInfo.formData.agency"
            style="width: 220px"
          />
        </el-form-item>
        <el-form-item
          label="Channel"
          prop="channel"
        >
          <el-input
            v-model="dialogInfo.formData.channel"
            style="width: 220px"
          />
        </el-form-item>
        <el-form-item
          label="Media Source"
          prop="mediaSource"
        >
          <el-input
            v-model="dialogInfo.formData.mediaSource"
            style="width: 220px"
          />
        </el-form-item>
        <el-form-item
          label="Cost"
          prop="cost"
        >
          <el-input
            v-model="dialogInfo.formData.cost"
            clearable
            style="width: 220px"
          >
            <template #append>
              USD
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="Revenue"
          prop="revenue"
        >
          <el-input
            v-model="dialogInfo.formData.revenue"
            clearable
            style="width: 220px"
          >
            <template #append>
              USD
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogInfo.show = false">取 消</el-button>
        <el-button
          :loading="dialogInfo.updateLoading"
          type="primary"
          plain
          @click="handleDialogSubmit"
        >保 存</el-button>
        <el-button
          :loading="dialogInfo.updateLoading"
          type="primary"
          @click="handleDialogSubmitAndContiue"
        >保存并新建</el-button>
      </span>
    </el-dialog>
  </Layout>
</template>

<script>
import { format } from 'fecha'
import {
  getUserNationStatistics
} from '@/api/electronicEntity.js'
import {
  getAllAgencyList,
  getAllMediaSourceList,
  addMarketCost,
  deleteMarketCost,
  getMarketCostList
} from '@/api/finance.js'
import {
  cloneDeep
} from 'lodash'

const getToday = () => {
  const targetDay = new Date(Date.now()).getTime()
  return [format(targetDay, 'YYYY-MM-DD'), format(targetDay, 'YYYY-MM-DD')]
}
const formKey = 'AGENCY_COST_FORM'

export default {
  name: 'DataWithAgency',
  data () {
    const numberCheck = (rule, value, callback) => {
      if (isNaN(value)) {
        callback(new Error('此项必须为数字'))
      } else {
        callback()
      }
    }

    const RANGE_CHECK = (rule, value, callback) => {
      if (value < 0) {
        callback(new Error('此项需不小于0'))
      } else {
        callback()
      }
    }

    const DECIMAL_CHECK = (rule, value, callback) => {
      const decimalLength = (value.toString().split('.')[1] || '').length || 0
      if (decimalLength > 2) {
        callback(new Error('小数位数不能多于2位'))
      } else {
        callback()
      }
    }
    return {
      // 下拉框数据
      nationList: [],
      filterNationList: [],
      agencyList: [],
      filterAgencyList: [],
      mediaSourceList: [],
      filterMediaSourceList: [],

      formData: {
        dateRange: getToday(),
        countryCodeList: [],
        agencyList: [],
        mediaSourceList: [],
        pageNum: 1,
        pageSize: 20
      },
      pickerOptions: {
        disabledDate (time) {
          const dateTime = time.getTime()
          return dateTime < new Date('2020-12-31').getTime() || dateTime > new Date(Date.now()).getTime()
        }
      },
      channelMap: {
        1: 'Android',
        2: 'iOS',
        3: 'All Channel'
      },
      listInfo: {
        list: [],
        total: 0,
        sumLtv1: 0,
        sumCost: 0,
        sumRoi: 0,
        loading: true
      },
      searchRules: {
        dateRange: [
          { required: true, message: 'Date range is required' }
        ],
        countryCodeList: [
          { required: true, message: 'Country is required' }
        ],
        agencyList: [
          { required: true, message: 'Agency is required' }
        ],
        mediaSourceList: [
          { required: true, message: 'Media Source is required' }
        ]
      },
      dialogInfo: {
        title: '新建数据',
        show: false,
        loading: false,
        updateLoading: false,
        formData: {},
        formRules: {
          elapsedTime: [
            { required: true, message: 'Date is required' }
          ],
          countryCode: [
            { required: true, message: 'Country is required' }
          ],
          agency: [
            { required: true, message: 'Agency is required' }
          ],
          channel: [
            { required: true, message: 'channel is required' }
          ],
          mediaSource: [
            { required: true, message: 'Media Source is required' }
          ],
          cost: [
            { required: true, message: 'Cost is required' },
            { validator: numberCheck },
            { validator: RANGE_CHECK },
            { validator: DECIMAL_CHECK }
          ],
          revenue: [
            { required: true, message: 'Revenue is required' },
            { validator: numberCheck },
            { validator: RANGE_CHECK },
            { validator: DECIMAL_CHECK }
          ]
        }
      }
    }
  },
  mounted () {
    Promise.all([
      this.queryNationList()
      // this.queryAgencyList(),
      // this.queryMediaSourceList()
    ]).then(() => {
      const storageFormData = sessionStorage.getItem(formKey)
      if (storageFormData) {
        this.listInfo.total = 9999
        this.formData = JSON.parse(storageFormData)
      }
      this.queryDataList()
    })
      .catch(() => {
        this.listInfo.loading = false
      })
  },
  methods: {
    // 表格显示相关方法
    numFormatter (row, column, cellValue, index) {
      return !isNaN(cellValue) ? cellValue : '-'
    },
    channelFormatter (row, column, cellValue, index) {
      return this.channelMap[cellValue] || 'All Channel'
    },
    // 下拉框相关方法
    filterCountryMethod (keywords) {
      this.filterNationList = this.filterList(keywords, this.nationList, 'nation')
    },
    filterAgencyMethod (keywords) {
      this.filterAgencyList = this.agencyList.filter(item => item.toLowerCase().startsWith(keywords.toLowerCase()))
    },
    filterMediaSourceMethod (keywords) {
      this.filterMediaSourceList = this.mediaSourceList.filter(item => item.toLowerCase().startsWith(keywords.toLowerCase()))
    },
    filterList (keywords, list, field) {
      return keywords ? list.filter(item => {
        return item[field].toLowerCase().startsWith(keywords.toLowerCase())
      }) : list
    },
    changeSelectState (listKey, fullList, scope) {
      scope[listKey].length === fullList.length
        ? scope[listKey] = []
        : scope[listKey] = fullList
    },
    selectButtonLabel (currentList, fullList) {
      return currentList.length === fullList.length ? '取消全选' : '全选'
    },
    // 数据相关方法
    formatQuery (formData) {
      const form = cloneDeep(formData)
      const [startTime, endTime] = form.dateRange
      delete form.dateRange
      return {
        ...form,
        startTime,
        endTime,
        countryCodeList: form.countryCodeList.map(item => {
          return item.countryCode
        })
      }
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    queryDataList () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.listInfo.loading = true
          const query = this.formatQuery(this.formData)
          sessionStorage.setItem(formKey, JSON.stringify(this.formData))
          getMarketCostList(query)
            .then(res => {
              if (res.code === 200) {
                this.listInfo.list = res.data.list || []
                this.listInfo.total = res.data.total || 0
                this.listInfo.sumCost = res.data.sumCost
                this.listInfo.sumLtv1 = res.data.sumLtv1
                this.listInfo.sumRoi = res.data.sumRoi
              }
            })
            .finally(() => {
              this.listInfo.loading = false
            })
        }
      })
    },
    queryNationList () {
      return getUserNationStatistics({
        searchWord: '',
        pageNum: 1,
        pageSize: 1000
      }).then(res => {
        if (res.code === 200) {
          const sortNationList = (res.data.list || []).sort((a, b) => {
            if (a.nation.toLowerCase() < b.nation.toLowerCase()) {
              return -1
            } else {
              return 1
            }
          })
          this.nationList = sortNationList
          this.filterNationList = sortNationList
          this.formData.countryCodeList = this.nationList
        }
      })
    },
    queryAgencyList () {
      return getAllAgencyList({})
        .then(res => {
          if (res.code === 200) {
            const sortAgencyList = (res.data.list || []).sort((a, b) => {
              if (a.toLowerCase() < b.toLowerCase()) {
                return -1
              } else {
                return 1
              }
            })
            this.agencyList = sortAgencyList
            this.filterAgencyList = sortAgencyList
            this.formData.agencyList = this.agencyList
          }
        })
    },
    queryMediaSourceList () {
      return getAllMediaSourceList({}).then(res => {
        if (res.code === 200) {
          const sortMediaSourceList = (res.data.list || []).sort((a, b) => {
            if (a.toLowerCase() < b.toLowerCase()) {
              return -1
            } else {
              return 1
            }
          })
          this.mediaSourceList = sortMediaSourceList
          this.filterMediaSourceList = sortMediaSourceList
          this.formData.mediaSourceList = this.mediaSourceList
        }
      })
    },
    // 新建与修改数据相关方法
    handleCreateNewData () {
      this.dialogInfo.title = '新建数据'
      this.dialogInfo.show = true
    },
    handleDialogClose () {
      this.dialogInfo.formData = {}
      this.$refs.subForm.resetFields()
    },
    handleDialogSubmitAndContiue () {
      this.handleDialogSubmit()
        .then(() => {
          this.handleCreateNewData()
        })
    },
    handleDialogSubmit () {
      return new Promise(resolve => {
        const isEdit = this.dialogInfo.title === '编辑数据'
        this.dialogInfo.updateLoading = true
        this.$refs.subForm.validate(valid => {
          if (valid) {
            if (isEdit) {
              this.doEditData().then(() => {
                resolve()
              })
            } else {
              this.doCreateData().then(() => {
                resolve()
              })
            }
          } else {
            this.dialogInfo.updateLoading = false
          }
        })
      })
    },
    doEditData () {
      return new Promise(resolve => {
        setTimeout(() => {
          this.dialogInfo.updateLoading = false
          this.dialogInfo.show = false
          this.queryDataList()
          resolve()
        }, 2000 * Math.random())
      })
    },
    doCreateData () {
      return new Promise(resolve => {
        addMarketCost(this.dialogInfo.formData)
          .then(res => {
            if (res.code === 200) {
              this.$message.success('新建数据成功')
              this.dialogInfo.show = false
              this.queryDataList()
              resolve()
            }
          })
          .finally(() => {
            this.dialogInfo.updateLoading = false
          })
      })
    },
    handleDeleteRowData (row) {
      this.$confirm('此操作将删除模块, 是否继续', '提示', { type: 'warning ' })
        .then(() => {
          deleteMarketCost({
            id: row.id
          }).then(res => {
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.queryDataList()
            }
          }).finally(() => {
          })
        })
    },
    downloadData () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const query = this.formatQuery(this.formData)
          delete query.pageNum
          delete query.pageSize
          // download data width query
          console.log(query)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .overview {
    display: flex;
    justify-content: space-between;
    // padding: 0 0 20px 0;
    min-width: 880px;
    margin-bottom: 10px;
    .left {
      display: flex;
      .items {
        // width: 210px;
        padding: 0 30px;
        height: 100px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num {
          font-size: 26px;
          margin-bottom: 10px;
          .unit {
            font-size: 14px;
          }
        }
        .name {
          font-size: 12px;
        }
      }
    }
  }
    .pre-header {
      height: 46px;
      .select-all {
        position: absolute;
        top: 0;
        z-index: 100;
        width: 100%;
        font-size: 14px;
        margin: 10px 0;
        padding: 10px 20px;
        cursor: pointer;
        background: #F5F7FA;
        box-sizing: border-box;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, .12);
        &::before {
          content: '';
          display: block;
          height: 10px;
          background: white;
          position: absolute;
          top: -10px;
          left: 0;
          width: 100%;
        }
        &:hover {
          color: cornflowerblue;
        }
      }
    }
</style>
