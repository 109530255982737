import request from '@/utils/request'

export function getUserNationStatistics (data) {
  return request({
    url: '/user/getUserNationStatistics',
    data
  })
}
export function importConsumeIncomeTemplate (data) {
  return request({
    url: '/dataHandling/importConsumeIncomeTemplate',
    data
  })
}
export function importDailyReportNowTemplate (data) {
  return request({
    url: '/dataHandling/importDailyReportNowTemplate',
    data
  })
}
export function importMonthlyCost (data) {
  return request({
    url: '/dataHandling/importMonthlyCost',
    data
  })
}
// eslint-disable-next-line camelcase
export function getKolData (data) {
  return request({
    url: '/dataHandling/getKolData',
    data
  })
}
export function getMonthlyCost (data) {
  return request({
    url: '/dataHandling/getMonthlyCost',
    data
  })
}
export function delStepData (data) {
  return request({
    url: '/dataHandling/delStepData',
    data
  })
}
export function calculation (data) {
  return request({
    url: '/dataHandling/calculation',
    data
  })
}
export function importMonthlyCostTemplate (data) {
  return request({
    url: '/dataHandling/importMonthlyCostTemplate',
    data
  })
}
export function importDailyReportNow (data) {
  return request({
    url: '/dataHandling/importDailyReportNow',
    data
  })
}
export function importConsumeIncome (data) {
  return request({
    url: '/dataHandling/importConsumeIncome',
    data
  })
}
export function addMallv2PhysicalGoods (data) {
  return request({
    url: '/mallv2/addMallv2PhysicalGoods',
    data
  })
}

export function getMallv2PhysicalGoodsList (data) {
  return request({
    url: '/mallv2/getMallv2PhysicalGoodsList',
    data
  })
}

export function getMallv2PhysicalGoodsDetail (data) {
  return request({
    url: '/mallv2/getMallv2PhysicalGoodsDetail',
    data
  })
}

export function updateMallv2PhysicalGoods (data) {
  return request({
    url: '/mallv2/updateMallv2PhysicalGoods',
    data
  })
}

export function updateMallv2PhysicalGoodsStatus (data) {
  return request({
    url: '/mallv2/updateMallv2PhysicalGoodsStatus',
    data
  })
}

export function deleteMallv2PhysicalGoodsStatus (data) {
  return request({
    url: '/mallv2/deleteMallv2PhysicalGoodsStatus',
    data
  })
}

export function getMallv2PhysicalGoodsOrderList (data) {
  return request({
    url: '/mallv2/getMallv2PhysicalGoodsOrderList',
    data
  })
}

export function updateMallv2PhysicalOrderLogisticsInfo (data) {
  return request({
    url: '/mallv2/updateMallv2PhysicalOrderLogisticsInfo',
    data
  })
}

export function updateMallv2PhysicalOrderStatus (data) {
  return request({
    url: '/mallv2/updateMallv2PhysicalOrderStatus',
    data
  })
}
