import request from '@/utils/request'

export function getFinancialDashboardList (data) {
  return request({
    url: '/count/getFinancialDashboardList',
    data
  })
}

export function getAllAgencyList (data) {
  return request({
    url: '/count/getAllAgencyList',
    data
  })
}

export function getAllMediaSourceList (data) {
  return request({
    url: '/count/getAllMediaSourceList',
    data
  })
}

export function getFinancialAgencyList (data) {
  return request({
    url: '/count/getFinancialAgencyList',
    data
  })
}

export function addMarketCost (data) {
  return request({
    url: '/count/AddMarketCost',
    data
  })
}

export function getMarketCostList (data) {
  return request({
    url: '/count/GetMarketCostList',
    data
  })
}
export function addOperationalCost (data) {
  return request({
    url: '/count/AddOperationalCost',
    data
  })
}
export function getOperationalCostListt (data) {
  return request({
    url: '/count/GetOperationalCostList',
    data
  })
}
export function deleteCategoryItem (data) {
  return request({
    url: '/count/DeleteOperationalCost',
    data
  })
}

export function deleteMarketCost (data) {
  return request({
    url: '/count/DeleteMarketCost',
    data
  })
}

export function addFinancialDownloadTask (data) {
  return request({
    url: '/count/addFinancialDownloadTask',
    data
  })
}

export function getDownloadTaskList (data) {
  return request({
    url: '/count/getDownloadTaskList',
    data
  })
}

export function getGameStatics (data) {
  return request({
    url: '/game/gameStatics',
    data
  })
}

export function downloadGameStatics (data) {
  return request({
    url: '/game/export/gameStatics',
    data
  })
}

export function getBindStremGameStatics (data) {
  return request({
    url: '/game/bindStremGameStatics',
    data
  })
}

export function downloadBindStremGameStatics (data) {
  return request({
    url: '/game/export/bindStremGameStatics',
    data
  })
}

export function queryTargetAndIncomeList (data) {
  return request({
    url: '/count/targetAndIncomeList',
    data
  })
}

export function setTarget (data) {
  return request({
    url: '/count/setTarget',
    data
  })
}
